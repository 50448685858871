import BaseBean from "@/utils/BaseBean";

export interface IAreaDataObj {
    utilInst:AreaUtil
    refMap:Map<string,any>
    treeParam:any
    gridParam:any
}
export default class AreaUtil extends BaseBean{
    public dataObj:IAreaDataObj;

    constructor(proxy:any,dataObj:IAreaDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
}
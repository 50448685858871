import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, nextTick, computed,watch,provide} from 'vue';
import AreaUtil ,{IAreaDataObj} from "./areaUtil";
export default defineComponent ({
    name: "area",
    components: {},
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('gridTableOwner', proxy);
        let dataObj:IAreaDataObj=reactive<IAreaDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            treeParam:{
                filterText:'',
                treeData:[],
                defaultProps:{
                    children: 'children',
                    label: 'F_NAME',
                },
                expandKeys:['china'],
                treeLoadFlag:true,
            },
            gridParam: {
                title:proxy.$t('area.detailTitle'),
                canPage:false,
                isShowIndexCol:false,
                rowKey:'id',
                staticData: true,
                gridLoadFlag:true
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new AreaUtil(proxy,dataObj);
        })
        onMounted(()=>{
            dataObj.gridParam.gridLoadFlag=false;
            dataObj.treeParam.treeLoadFlag=true;
            nextTick(async ()=>{
                dataObj.treeParam.treeData=await utils.Api.loadProvinceCityAreaTreeData();
                dataObj.treeParam.treeLoadFlag=false;
                //计算表格的最大高度
                let tbMaxHeight=(window.innerHeight-utils.Const.topHeight-utils.Const.tabHeight-40);
                dataObj.refMap.get('gridRef1').setMaxTbHeight(tbMaxHeight);
            })
        })
        //树节点单击事件
        const nodeClick=(nodeData:any)=>{
            dataObj.gridParam.gridLoadFlag=true;
            dataObj.refMap.get('gridRef1').tbTitle=nodeData.F_NAME;
            if(nodeData.children.length>0){
                dataObj.refMap.get('gridRef1').setTbData(nodeData.children);
            }else{
                dataObj.refMap.get('gridRef1').setTbData([nodeData]);//表格接收的是数组，所以加[]
            }
            dataObj.gridParam.gridLoadFlag=false;
        }
        //监听输入框变化，调用tree的过滤方法
        watch(() => dataObj.treeParam.filterText,async (newValue,oldValue) => {
            dataObj.refMap.get('treeRef').filter(newValue);
        })
        //树的过滤方法
        const filterTree=(value: string, data: any)=>{
            if (!value) return true;
            return data.F_NAME.includes(value)
        }
        return{
            ...toRefs(dataObj),nodeClick,filterTree
        }
    }
});